/* You can add global styles to this file, and also import other style files */

@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.scss';
@import '~jsoneditor/dist/jsoneditor.min.css';

$ri-primary: #1da1f2;
$ri-review: #2b5a84;
$ri-danger: #dc1212;
$ri-edited: lighten(
  $color: $ri-primary,
  $amount: 20%
);
$ri-invalid: lighten(
  $color: $ri-danger,
  $amount: 36%
);

:root {
  --bs-body-font-size: 14px;
  --bs-primary: #{$ri-primary};
  // --bs-primary-rgb: #{$ri-primary};
  --bs-grey: #949c9e;
  --bs-body-bg: #fafafa;
  --bs-border-color: #e0e0e0;

  --ri-review: #{$ri-review};
  --ri-edited: #{$ri-edited};
  --ri-invalid: #{$ri-invalid};
  --ri-white: #fff;

  --mdc-filled-text-field-container-color: var(--bs-body-bg);
  --mat-select-trigger-text-size: var(--bs-body-font-size);
  --mat-form-field-label-text-size: var(--bs-body-font-size);
  --mat-form-field-container-text-size: var(--bs-body-font-size);
  --mdc-filled-text-field-label-text-size: var(--bs-body-font-size);
  --mdc-filled-text-field-active-indicator-height: 0;
  --mat-form-field-filled-with-label-container-padding-bottom: 2px;
  --mdc-filled-text-field-focus-active-indicator-height: 0;
  --mat-form-container-height: 40px;
  --mat-form-field-container-text-line-height: 16px;
}

html,
body {
  height: 100%;
}
body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-5 {
  width: 5% !important;
}

.mat-mdc-form-field {
  background-color: transparent;
  border: none;
  border-radius: 4px;

  input {
    height: 24px !important;
  }

  .mat-mdc-text-field-wrapper {
    border-radius: 4px;
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
    // padding-bottom: 4px;
  }

  .mdc-text-field {
    padding: 0 8px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-underline {
    display: none;
  }

  &.mat-focused {
    background-color: var(--bs-body-bg);
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }
}

.mat-mdc-tab-body-wrapper {
  height: calc(100vh - 215px);
  overflow: scroll;
}

// .mat-mdc-dialog-surface {
//   padding: 16px;
// }

.cdk-overlay-pane {
  width: unset !important;
  height: unset !important;
  max-width: 500px !important;
  min-width: 200px !important;
  .mat-mdc-select-panel {
    padding: 0;
    font-size: var(--bs-body-font-size);
    // height: unset !important;
    // max-height: 600px !important;
  }
}

.ri-hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
}

.edited .mat-mdc-text-field-wrapper {
  background-color: var(--ri-edited) !important;
  border-radius: 4px;
}

// .mat-mdc-form-field {
//   background-color: transparent;
//   border: none;

//   mat-label {
//     font-size: var(--bs-body-font-size) !important;
//   }

//   input {
//     font-size: var(--bs-body-font-size) !important;
//   }

//   .mat-mdc-form-field-label {
//     font-size: var(--bs-body-font-size) !important;
//   }

//   .mat-mdc-form-field-input-control {
//     font-size: var(--bs-body-font-size) !important;
//   }

//   .mat-mdc-form-field-subscript-wrapper {
//     display: none;
//   }

//   .mat-mdc-form-field-underline {
//     display: none;
//   }

//   .mat-mdc-select {
//     font-size: var(--bs-body-font-size) !important;
//   }

//   .mat-mdc-select-trigger {
//     font-size: var(--bs-body-font-size) !important;
//   }

//   .mat-mdc-select-panel {
//     font-size: var(--bs-body-font-size) !important;
//   }
// }

// :root {
//   --bs-primary: #1da1f2;
//   --bs-grey: #949c9e;
//   --bs-body-bg: #fafafa;
//   --ri-white: #fff;
//   --mdc-filled-text-field-container-color: var(--bs-body-bg);
//   --ri-review: #2b5a84;
//   --bs-body-font-size: 14px;
//   --mat-select-trigger-text-size: 14px;
//   --mat-mat-form-field-label-text-size: 14px;
//   --mat-mat-form-field-container-text-size: 14px;
//   --mdc-filled-text-field-label-text-size: 14px;
// }

textarea.jsoneditor-text {
  min-height: 900px;
}

.jsoneditor {
  border: none;
}

// ::ng-deep .highlight {
//   background-color: red;
//   color: white;
// }

// ::ng-deep .mat-grid-tile-content {
//   display: block !important;
// }

// .text-bg-primary {
//   background-color: var(--bs-primary) !important;
//   color: var(--ri-white) !important;
// }

.ri-pointer {
  cursor: pointer;
}

// .disabled {
//   cursor: not-allowed !important;
// }

// .invalid .mat-mdc-text-field-wrapper {
//   background-color: red !important;
//   color: white !important;
// }

.mdc-line-ripple::before {
  display: none !important;
}
.mat-mdc-tab-labels {
  border-bottom: 0.5px solid var(--bs-grey);
  .mat-mdc-tab.mdc-tab {
    flex-grow: 1 !important;
  }
}

// Mainly for image viewer
.mat-mdc-dialog-content,
.mat-mdc-dialog-panel {
  max-height: 90vh !important;
  max-width: 90vw !important;
}

// .mat-mdc-form-field-subscript-wrapper {
//   display: none;
// }

// .mat-mdc-form-field-input-control {
//   font-size: 14px;
// }

.ri-paginator {
  // margin-bottom: 300px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--bs-body-bg);
  z-index: 1000;
  border-top: 1px solid var(--bs-grey);
  .mat-mdc-paginator-page-size {
    align-items: center;
  }
  .mat-mdc-paginator-container {
    justify-content: flex-start;
  }
}

.bg-review {
  background-color: var(--ri-review) !important;
  color: var(--ri-white) !important;
}

.btn-review {
  background-color: var(--ri-review) !important;
  color: var(--ri-white) !important;

  &:hover {
    background-color: lighten($ri-review, $amount: 5);
    color: var(--ri-white) !important;
  }
}

.text-bg-review {
  background-color: var(--ri-review) !important;
  color: var(--ri-white) !important;
}

.text-bg-primary {
  background-color: var(--bs-primary) !important;
  color: var(--ri-white) !important;
}

table {
  border-top: 1px solid var(--bs-border-color) !important;
  border-bottom: 1px solid var(--bs-border-color) !important;
  margin-bottom: 100px;

  tr {
    height: 40px !important;
  }

  thead th.mat-mdc-table-sticky {
    z-index: 101;
    // border-bottom: 1px solid var(--bs-grey);
  }
}

.ri-highlight {
  background-color: var(--ri-review) !important;
  color: white;
}

.ri-w-50 {
  width: 50px !important;
}

.ri-min-w-50 {
  min-width: 50px !important;
}

.ri-max-w-50 {
  max-width: 50px !important;
}

.ri-w-75 {
  width: 75px !important;
}

.ri-min-w-75 {
  min-width: 75px !important;
}

.ri-max-w-75 {
  max-width: 75px !important;
}

.ri-w-100 {
  width: 100px !important;
}

.ri-max-w-100 {
  max-width: 100px !important;
}

.ri-min-w-100 {
  min-width: 100px !important;
}

.ri-w-150 {
  width: 150px !important;
}

.ri-max-w-150 {
  max-width: 150px !important;
}

.ri-min-w-150 {
  min-width: 150px !important;
}

.ri-w-200 {
  width: 200px !important;
}

.ri-max-w-200 {
  max-width: 200px !important;
}

.ri-min-w-200 {
  min-width: 200px !important;
}

.ri-w-250 {
  width: 250px !important;
}

.ri-min-w-250 {
  min-width: 250px !important;
}

.ri-max-w-250 {
  max-width: 250px !important;
}

.ri-w-300 {
  width: 300px !important;
}

.ri-min-w-300 {
  min-width: 300px !important;
}

.ri-max-w-300 {
  max-width: 300px !important;
}

.ri-w-350 {
  width: 350px !important;
}

.ri-min-w-350 {
  min-width: 350px !important;
}

.ri-max-w-350 {
  max-width: 350px !important;
}

.ri-w-400 {
  width: 400px !important;
}

.ri-min-w-400 {
  min-width: 400px !important;
}

.ri-max-w-400 {
  max-width: 400px !important;
}

.ri-w-450 {
  width: 450px !important;
}

.ri-min-w-450 {
  min-width: 450px !important;
}

.ri-max-w-450 {
  max-width: 450px !important;
}
.ri-w-500 {
  width: 500px !important;
}

.ri-min-w-500 {
  min-width: 500px !important;
}

.ri-max-w-500 {
  max-width: 500px !important;
}

.ri-w-600 {
  width: 600px !important;
}

.ri-min-w-600 {
  min-width: 600px !important;
}

.ri-max-w-600 {
  max-width: 600px !important;
}

.ri-min-w-700 {
  min-width: 700px !important;
}

.ri-max-w-700 {
  max-width: 700px !important;
}

.ri-w-700 {
  width: 800px !important;
}

.ri-w-800 {
  width: 800px !important;
}

.ri-min-w-800 {
  min-width: 800px !important;
}

.ri-w-900 {
  width: 900px !important;
}

.ri-min-w-900 {
  min-width: 900px !important;
}

.ri-w-950 {
  width: 950px !important;
}

.ri-min-w-950 {
  min-width: 950px !important;
}

.ri-w-1000 {
  width: 1000px !important;
}

.ri-min-w-1000 {
  min-width: 1000px !important;
}

.ri-min-h-300 {
  min-height: 300px !important;
}

.ri-min-h-400 {
  min-height: 400px !important;
}

.ri-max-h-400 {
  max-height: 400px !important;
}

.ri-fs-10px {
  font-size: 10px;
}

.ri-fs-12px {
  font-size: 12px;
}

.ri-fs-14px {
  font-size: 14px;
}

.ri-fs-16px {
  font-size: 16px;
}

.ri-fs-18px {
  font-size: 18px;
}

.ri-lh-24px {
  line-height: 24px;
}

.ri-filter-row-background {
  background-color: lightgray;
}

.ri-disabled {
  pointer-events: none !important;
  opacity: 0.65 !important;
}

.mat-mdc-optgroup-label {
  padding: 0 !important;
  .mdc-list-item__primary-text {
    width: 100%;
  }
}

.ri-pe-none {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}

.ri-filter-row {
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-container-height: 40px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0;
  // .mat-mdc-form-type-mat-select {
  //   --mat-form-field-filled-with-label-container-padding-top: 12px;
  // }
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  // @extend .ri-hide-scrollbar;
  padding: 4px 0 4px 4px;

  .mat-form-field-infix {
    padding: 0;
  }

  .mat-mdc-form-field-infix {
    padding-bottom: 2px !important;
  }

  // .mat-form-field-appearance-fill .mat-form-field-flex {
  //   padding: 4px 4px 4px 8px;
  // }

  .mat-mdc-form-field {
    flex-grow: 1;
    @extend .me-1;
    .mat-icon {
      font-size: 20px !important;
    }

    // Only for search input
    &.mat-mdc-form-field-label-always-float,
    &.mat-mdc-form-field-has-icon-suffix {
      button {
        padding: 0 !important;
        width: 24px !important;
        height: 24px !important;
      }

      // .mat-mdc-form-field-infix {
      //   padding-top: 16px !important;
      //   padding-bottom: 2px !important;
      // }
    }

    .mat-mdc-input-element {
      padding: 4px 0 !important;
    }
  }

  button {
    @extend .text-nowrap;
    @extend .me-1;
  }

  // &.mat-mdc-form-field-has-icon-suffix {
  //   width: 100%;
  //   border: none;
  //   outline: none;
  //   background-color: transparent;
  //   &:focus-visible {
  //     border: none;
  //     outline: none;
  //   }
  //   @extend .me-1;

  // }

  .ri-filter-count {
    position: relative;
    top: 10px;
    right: 4px;
    margin-left: 8px;
    @extend .me-1;
  }
}

.ri-bottom-spacer {
  height: 100px;
}

.mat-mdc-option {
  font-size: 14px !important;
  height: unset !important;
  line-height: 24px !important;
  min-height: 32px !important;
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.8) !important; /* Adjust the alpha value to your preference */
}

.mat-mdc-dialog-container {
  max-height: 90vh !important;
}

.mat-bottom-sheet-container {
  max-height: 90vh !important;
  padding-top: 0 !important;
}

.widget-detail-edit-bottom-sheet {
  .mat-bottom-sheet-container {
    width: 600px;
    height: 90vh;
    padding: 0 !important;
  }
}

.ri-bottom-sheet {
  .mat-bottom-sheet-container {
    width: 900px;
    height: 90vh;
    padding: 1rem !important;
  }
}

.ri-modal {
  .mat-mdc-dialog-container {
    padding: 0 !important;
  }
}

.w-20 {
  width: 20% !important;
}

.w-12-5 {
  width: 12.5% !important;
}

.w-17-5 {
  width: 17.5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-33 {
  width: 33.33% !important;
}

.btn-activity,
.btn-comment,
.btn-restore {
  display: flex;
  align-items: center;
  mat-icon {
    font-size: 18px;
    width: 18px;
    height: 16px;
    display: flex;
    align-items: center;
  }
}

.ri-btn-delete {
  height: 30.78px;
  display: flex;
  align-items: center;
  .bi-trash {
    display: flex;
    align-items: center;
  }
}

.mat-datepicker-content .mat-calendar {
  height: 396px !important;
}

.bg-dark-danger {
  background-color: #710808;
}

.text-bg-dark-danger {
  background-color: #710808;
  color: #FFFF;
}

.bg-purple {
  background-color: #8860d6;
}

.text-bg-purple {
  background-color: #8860d6;
  color: #FFFF;
}

.bg-dark-purple {
  background-color: #322251;
}

.text-bg-dark-purple {
  background-color: #322251;
  color: #FFFF;
}

.bg-dark-success {
  background-color: #0c3d0c;
}

.text-bg-dark-success {
  background-color: #0c3d0c;
  color: #FFFF;
}

// Diff to html css overwrite 

.d2h-wrapper .d2h-diff-table tr {
  height: 22px !important;

}

.daily-plan-type .mat-button-toggle-label-content {
  line-height: 34px !important;
}

.ri-mat-textarea {
  z-index: 2;
}
.ri-mat-textarea .mdc-text-field,
.ri-mat-textarea .mat-mdc-form-field-infix {
    position: unset !important;
}

.ri-tag-editor span,
.ri-comment-text span {
  background-color: #d1e7dd;
  color: #0f5132;
  border-radius: 4px;
  padding: 2px 4px;
  font-weight: bold;
}

.mat-tooltip {
  white-space: nowrap;
}

.select-only .mat-mdc-select-trigger {
  height: 100%;
}

.date-range-picker .mat-date-range-input .mat-date-range-input-wrapper,
.date-range-picker .mat-mdc-form-field input {
  height: 19px !important; /* Adjust height as needed */
  line-height: 19px; /* Align text vertically within the input */
}
// Scroll bar codes

/* Custom Scrollbar for Webkit-based browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
}

/* Scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Scrollbar thumb when active (clicked) */
::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Container hover state to show scrollbar */
:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

:root .my-custom-ngx-diff-theme {
  --ngx-diff-border-color: #888888;
  --ngx-diff-font-size: 12px;
  --ngx-diff-font-family: Consolas, Courier, monospace;
  --ngx-diff-font-color: #000000;
  --ngx-diff-line-number-font-color: #484848;
  --ngx-diff-line-number-hover-font-color: #ffffff;
  --ngx-diff-selected-border-color: #ff8000;
  --ngx-diff-inserted-background-color: #9dff97;
  --ngx-diff-deleted-background-color: #ff8c8c;
  --ngx-diff-equal-background-color: #ffffff;
  --ngx-diff-margin-background-color: #dedede;
  --ngx-diff-line-number-width: 30px;
  --ngx-diff-border-width: 6px;
  --ngx-diff-line-left-padding: 12px;
  --ngx-diff-bottom-spacer-height: 12px;
}
